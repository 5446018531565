export default {
  changePassword: {
    password: {
      required: true,
      min: 8,
      newUserPassword: true
    },
    password_confirm: {
      required: true,
      confirmed: 'password'
    }
  }
}
