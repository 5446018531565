<template src="./changePartnerPassword.html"></template>
<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import FieldError from '@/components/forms/FieldError'
import { changePasswordSSO } from '@/api/sso'
import validations from './changePartnerPassword.validations'
import PartnerLogo from '@/components/PartnerLogo'
import SwoopLogo from '@/components/SwoopLogo'

export default {
  name: 'ChangePartnerPassword',
  components: {
    ValidationObserver,
    ValidationProvider,
    FieldError,
    PartnerLogo,
    SwoopLogo
  },
  data () {
    return {
      validations,
      password: '',
      password_confirm: '',
      pending: false,
      errorResponse: null,
      passwordToken: '',
      partnerName: ''
    }
  },
  methods: {
    changePasswordSubmitHandler (validate) {
      return validate()
        .then(result => {
          if (result) {
            const payload = { password: this.password, changePasswordToken: this.passwordToken }
            this.pending = true
            this.errorResponse = null
            return changePasswordSSO(payload).then(response => {
              this.pending = false
              this.$router.push({ name: 'partner-password-verification', query: { partner: this.partnerName } })
            })
          }
        })
        .catch(error => {
          this.pending = false
          this.errorResponse = error.response
        })
    }
  },
  beforeMount () {
    const { token, partner } = this.$route.query
    if (token) {
      this.passwordToken = token
    }
    if (partner) {
      this.partnerName = partner
    }
  }
}
</script>

<style lang="scss" scoped>

.top-logo {
  display: flex;
  justify-content: center;
  .top-main-logo {
    margin: 0 16px;
  }
}
</style>
